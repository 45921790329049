<template>
  <el-card style="min-height: 45rem">

  <el-row :gutter="20">
      <el-col :span="12">
        <el-form label-width="10rem" style="padding: 2rem;width: 30rem">
          <el-form-item label="阿里AccessKeyID">
            <el-input v-model="form.sns_ali_access_key_id"></el-input>
          </el-form-item>
          <el-form-item label="阿里AccessKeySecret">
            <el-input v-model="form.sns_ali_access_secret"></el-input>
          </el-form-item>
          <el-form-item label="阿里短信签名">
            <el-input v-model="form.sns_ali_sign_name"></el-input>
          </el-form-item>
          <el-form-item label="验证码阿里短信模版ID">
            <el-input v-model="form.sns_ali_tpl_code"></el-input>
            <div class="y-desc">验证码变量 ：${number}</div>
            <div class="y-desc">模版示例 ：验证码${number}，打死不告诉别人！</div>
          </el-form-item>
          <el-form-item label="佣金到账阿里短信模版ID">
            <el-input v-model="form.sns_ali_tpl_award"></el-input>
            <div class="y-desc">验证码变量 ：${fee}</div>
            <div class="y-desc">模版示例 ：佣金到账${fee}元！</div>
          </el-form-item>
          <el-form-item>
            <el-button @click="submit" type="primary">保存</el-button>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>

  </el-card>
</template>

<script>
export default {
  name: "sns",
  components:{
  },
  data(){
    return{
      form:{
        sns_ali_access_key_id:"",
        sns_ali_access_secret:"",
        sns_ali_sign_name:"",
        sns_ali_tpl_code:"",
        sns_ali_tpl_award:"",
      }
    }
  },
  mounted() {
    this.load();
  },
  methods:{
    load(){
      this.$api.system.info.sns().then(res=>{
        this.form = res;
      })
    },
    submit(){
      this.$api.system.info.snsEdit(this.form).then(()=>{
        this.$message.success("操作成功");
      })
    }
  }
}
</script>

<style scoped>

</style>